import React from 'react';

import Master from '../templates/Master.jsx';
import SEO from '../components/shared/seo.jsx';

const NotFoundPage = () => (
	<Master>
		<SEO title="404: Not found" />
		<h1>NOT FOUND</h1>
		<p>You just hit a route that doesn&#39;t exist... the sadness.</p>
	</Master>
);

export default NotFoundPage;
