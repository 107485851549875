import React, {useState, useEffect} from 'react';
import {Link} from 'gatsby';

import {HeaderContainer, Inner, Nav, NavList} from './HeaderStyles';
import Logo from './Logo';
import SearchButton from '../shared/SearchButton';

// const isMobile = window.innerWidth <= 640;

const Header = () => {
	const [width, setWidth] = useState('');

	useEffect(() => {
		setWidth(window.innerWidth);
		const handleResize = () => setWidth(window.innerWidth);
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	});
	return (
		<HeaderContainer>
			<Inner>
				<Link to="/">
					<Logo />
				</Link>
				<Nav>
					<NavList>
						<li>
							<Link to="/about">About</Link>
						</li>
						<li>
							<Link to="/picture-day">Pictures</Link>
						</li>
						<li>
							<Link to="/sports">Sports</Link>
						</li>
						<li>
							<Link to="/contact">Contact</Link>
						</li>
					</NavList>
					{/* {(() => {
						if (width >= 1024) {
							return <SearchButton header />;
						}
					})()} */}
				</Nav>
			</Inner>
		</HeaderContainer>
	);
};

export default Header;
