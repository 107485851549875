import styled from 'styled-components';
import Logo from './Logo';

export const HeaderContainer = styled.header`
	box-shadow: 1px 2px 6px 0 rgba(0, 0, 0, 0.15);
	position: fixed;
	top: 0;
	left: 0;
	z-index: ${({theme}) => theme.layers.overlay};
	width: 100%;
	background-color: ${({theme}) => theme.colors.secondary};
	color: #fff;
`;
export const Inner = styled.div`
	max-width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	${({theme}) => theme.container};
	height: 100px;
	padding: 0 1rem;
`;
export const Nav = styled.nav`
	display: flex;
	justify-content: flex-end;
	width: 80%;
	margin-left: auto;
`;
export const NavList = styled.ul`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	list-style: none;
	margin: 0;
	margin-right: 3rem;
	padding: 0 2rem;
	width: 50%;
	li {
		padding: 0 1rem;
	}
	a {
		color: #ffffff;
		text-decoration: none;
		font-size: 1rem;
		font-weight: bold;
		transition: 0.2s all ease-in-out;
		&:hover {
			color: #10615f;
		}
	}
	@media (max-width: 640px) {
		flex-wrap: wrap;
		margin-right: 0;
	}
`;
