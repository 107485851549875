/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import {useStaticQuery, graphql} from 'gatsby';

const SEO = ({description, lang, meta, title}) => {
	const site = useStaticQuery(
		graphql`
			query {
				kontentItemSiteMetadata {
					elements {
						description {
							value
						}
						title {
							value
						}
					}
				}
			}
		`
	);

	const metaDescription =
		description || site.kontentItemSiteMetadata.elements.description.value;

	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={title}
			titleTemplate={`${site.kontentItemSiteMetadata.elements.title.value} | %s`}
			meta={[
				{
					name: `description`,
					content: metaDescription,
				},
				{
					name: `keywords`,
					content: `Tobie Andrews, Photography, tobieandrewsphotography, school photos, wichita school photos, school photography`,
				},
				{
					property: `og:title`,
					content: title,
				},
				{
					property: `og:description`,
					content: metaDescription,
				},
				{
					property: `og:type`,
					content: `website`,
				},
				{
					name: `twitter:card`,
					content: `summary`,
				},
				{
					name: `twitter:creator`,
					content: site.kontentItemSiteMetadata.elements.title.value,
				},
				{
					name: `twitter:title`,
					content: title,
				},
				{
					name: `twitter:description`,
					content: metaDescription,
				},
			].concat(meta)}
		/>
	);
};

SEO.defaultProps = {
	lang: `en`,
	meta: [],
	description: ``,
};

SEO.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
	title: PropTypes.string.isRequired,
};

export default SEO;
