import React from 'react';
import styled from 'styled-components';

import Logo from './Logo';

const FooterContainer = styled.footer`
	display: flex;
	justify-content: center;
	height: 100px;
	background-color: ${({theme}) => theme.colors.secondary};
`;

const Inner = styled.div`
	${({theme}) => theme.container};
	padding: 1rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	> div {
		max-width: 200px;
	}
	p {
		font-size: 0.75rem;
		color: #ffff;
	}
`;
const currentYear = new Date().getFullYear();

const Footer = () => {
	return (
		<FooterContainer>
			<Inner>
				<Logo />
				<p>
					&copy; {currentYear} TAP School Photos, LLC. All rights reserved.
				</p>
			</Inner>
		</FooterContainer>
	);
};

export default Footer;
