const brand = {
	green: '#149c97',
	darkGreen: '#10615f',
	navy: '#252839',
	black: '#1A1F22',
	textGray: '#465158',
	white: '#FFFFFF',
	bgGray: '#F4F4F4',
};

const fonts = {
	sans: 'poppins, sans-serif',
	serif: '"Lora", serif',
};

const theme = {
	brand,
	colors: {
		primary: brand.darkGreen,
		secondary: brand.green,
		background: brand.bgGray,
		text: brand.black,
	},
	container: {
		width: '100%',
		maxWidth: '90rem',
		margin: '0 auto',
	},
	content: {
		maxWidth: '50%',
		marginLeft: '8.3333%',
	},
	fonts,
	layers: {
		root: -1,
		base: 1,
		content: 10,
		overlay: 100,
		priority: 1000,
	},
};

export default theme;
