import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
// import PropTypes from 'prop-types';

const Container = styled.div`
	margin: 0;
	/* border: 1px solid #ffffff; */
	padding: 1rem;
	background-color: ${({theme}) => theme.colors.secondary};
`;
const Button = styled.button`
	background-color: ${({theme}) => theme.colors.primary};
	color: #fff;
	text-transform: uppercase;
	font-weight: bold;
	padding: 0.5rem 1rem;
	width: max-content;
	border: 2px solid #ffffff;
	appearance: none;
	transition: 0.25s all ease-in-out;
	cursor: pointer;
	:hover {
		background-color: #ffffff;
		color: ${({theme}) => theme.colors.primary};
	}
	@media (max-width: 640px) {
		font-size: 0.875rem;
	}
`;
const InputContainer = styled.div`
	display: block;
`;

const Input = styled.input`
	margin-right: 0.5rem;
	border: 2px solid #ffffff;
	outline: none;
	box-shadow: none;
	padding: 0.5rem;
`;

const SearchButton = ({header}) => {
	const [code, setCode] = useState('');

	const handleCodeInput = (e) => {
		e.preventDefault();
		let schoolPrefix = code.substring(0, 3).toUpperCase();
		const schools = {
			CCA: 'ccalions',
			CSW: 'CSW-Classical-School-Wichita',
			CSA: 'Christ-Savior-Academy',
			IND: 'The-Independent-School',
			DPP: 'Discovery-Place-Preschool',
			ENC: 'encore',
			KDA: 'kda',
			KDS: 'kds',
			TIS: 'TIS',
			TAK: 'tak2122',
			CSW: 'CSW',
			WCT: 'wctd',
			RLA: 'funrla',
		};
		console.log(schools[schoolPrefix]);
		if (code) {
			window.open(
				`https://tapschoolphotos.zenfolio.com/${schools[schoolPrefix]}/?eq=${code}`
			);
		} else {
			alert('Please enter your student code.');
		}
	};

	return (
		<Container header={header}>
			<InputContainer>
				<form
					onSubmit={handleCodeInput}
					style={{display: 'flex', height: '40px'}}>
					<Input
						type="text"
						name="search"
						placeholder="Enter your code..."
						onChange={(e) => setCode(e.target.value)}
					/>
					<Button type="submit">View photos</Button>
				</form>
			</InputContainer>
		</Container>
	);
};

// SearchButton.propTypes = {
// 	SearchButton: PropTypes.string,
// };

export default SearchButton;
