import {useStaticQuery, graphql} from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const LogoContainer = styled.div`
	max-width: 250px;
	img {
		display: inline-block;
		width: 100%;
	}
	@media (max-width: 640px) {
		min-width: 150px;
	}
`;

const Logo = () => {
	const file = useStaticQuery(
		graphql`
			query {
				kontentItemSiteMetadata {
					elements {
						logo {
							value {
								url
							}
						}
					}
				}
			}
		`
	);
	return (
		<LogoContainer>
			<img
				src={file.kontentItemSiteMetadata.elements.logo.value[0].url}
			/>
		</LogoContainer>
	);
};

export default Logo;
