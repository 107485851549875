/* eslint-disable no-unused-vars */
// import {graphql, useStaticQuery} from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';

import Header from '../components/navigation/Header.jsx';
import Footer from '../components/navigation/Footer.jsx';
import ThemeWrapper from '../components/layouts/ThemeWrapper.jsx';

/* eslint-enable no-unused-vars */

import('../global/global.scss');

const ContentWrapper = styled.div`
	width: 100%;
`;

const Master = ({children}) => {
	return (
		<div style={{marginTop: '100px'}}>
			<Helmet>
				<link
					href="https://fonts.googleapis.com/css?family=Lora:400,400i,700,700i"
					rel="stylesheet"
				/>
			</Helmet>
			<ThemeWrapper>
				<ContentWrapper>
					<Header />
					{children}
					<Footer />
				</ContentWrapper>
			</ThemeWrapper>
		</div>
	);
};

Master.propTypes = {
	children: PropTypes.node,
};

export default Master;
